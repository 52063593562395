#demo-chat-container {
  display: flex;
  flex-direction: row;
}
#demo-chat-container #__demo-chat-widget {
  transition:
    width 200ms,
    height 200ms,
    max-height 200ms,
    transform 300ms cubic-bezier(0, 1.2, 1, 1),
    opacity 83ms ease-out;
}
.chat-widget-overlay {
  opacity: 0;
  transform: scale(0.95);
  animation: fadeInScale 0.7s ease-in-out forwards;
}
@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
body > #cx-chat-container {
  opacity: 0;
}
svg.animate-spin.h-12.w-12.text-blue-500 {
  width: 22%;
  height: 22%;
  justify-content: center;
  align-items: center;
  margin-top: 130px;
}
